<template>
  <ClFormRow :label="label" :toolTipText="toolTipText" name="unitOfMeasure" ref="unitOfMeasureRef">
    <template #content>
      <multiselect
        @search-change="onInput"
        v-model="selectedUnitOfMeasure"
        :options="unitOfMeasureOptions"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
        :searchable="searchable"
        placeholder="Type here..."
      >
        <span slot="noOptions"></span>
      </multiselect>
      <img
        src="../../assets/icons/search.svg"
        width="16"
        height="16"
        alt="search"
        class="zq--multiselect-search-icon"
      />
    </template>
  </ClFormRow>
</template>

<script>
import { formConfig } from "@/config";
import { mapActions } from 'vuex';

export default {
  name: "ZqSelectUnitOfMeasure",
  props: {
    value: [String, Object],
    label: String,
    toolTipText: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedUnitOfMeasure: null,
      selectLabel: formConfig.selectLabel,
      unitOfMeasureOptions: [],
      initOptions: null,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('unitsOfMeasure', ['handleGetUnitsOfMeasureByQuery', 'handleGetUnitsOfMeasure_item']),
    async initialize() {
      if (this.value) {
        this.selectedUnitOfMeasure = await this.handleGetUnitsOfMeasure_item([this.value], 1, 0)
      } else {
        this.initOptions = await this.handleGetUnitsOfMeasureByQuery({
          queryRequest: {
            sortBy: [
              {
                queryField: 'name',
                order: 'Asc',
              },
            ],
            limit: 20
          }
        });

        this.unitOfMeasureOptions = this.initOptions;
      }
    },
    async onInput(val) {
      if (val.length > 1) {
        this.unitOfMeasureOptions = await this.handleGetUnitsOfMeasureByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ]
          }
        })
        if (val && val.length) {
          this.$refs.unitOfMeasureRef.$el.classList.remove('zq-invalid');
        }
      }
      if (val.length === 0) {
        this.unitOfMeasureOptions = this.initOptions ?? [];
      }
    }
  },
  watch: {
    selectedUnitOfMeasure: {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.$emit('input', val[0].id)
        } else {
          this.$emit('input', val.id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>